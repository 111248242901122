const appConfig =
{
    apiPrefix:                  'https://inspec.dgehm.gob.sv/api',
    locale:                     'es',

    logoSrc:                    'https://inspec.dgehm.gob.sv/api/images/general/logo.png',
    escudoSrc:                  'https://inspec.dgehm.gob.sv/api/images/general/escudo.png',
    authenticatedEntryPath:     '/complaints',

    unAuthenticatedEntryPath:   '/signin',
    changePasswordEntryPath:    '/change-password',
    tourPath:                   '/',
    enableMock:                 false
};

export default appConfig;
